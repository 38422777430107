import LogicTableIcon from "./logic_table_icon.png";
import HypixelIcon from "./hypixel_icon.png";
import PlantestIcon from "./plantest_icon.png";
import WikiRumbleIcon from "./wikirumble_icon.png";
import SandtrisIcon from "./sandtris_icon.png";

import face0 from "../images/face0.png";
import face1 from "../images/face1.png";
import face2 from "../images/face2.png";
import face3 from "../images/face3.png";
import face4 from "../images/face4.png";
import face5 from "../images/face5.png";
import face6 from "../images/face6.png";

export const projectObjs = [
  {
    title:"Sandtris",
    description: "A casual falling block game using my custom sand automata engine. Made using P5.js",
    link:"https://sandtris.com/",
    image:SandtrisIcon,
  },
  {
    title:"Hypixel Bazaar Flip Calculator",
    description:  "A trading tool that uses Hypixel’s Bazaar data API to rank and calculate the best items to flip on their online market.",
    link:"https://fanrco.github.io/HypixelBazaarFlipCalculator/",
    image:HypixelIcon,
  },
  {
    title:"WikiRumble",
    description: "A web based casual trivia game that puts random Wikipedia articles head-to-head. This game uses the Wikipedia API and was made using React.",
    link:"https://fanrco.github.io/WikiRumble/",
    image:WikiRumbleIcon,
  },
  {
    title: "Logic Table Generator",
    description: "A web app that parses logical sentences and produces their full logic table. The app also checks joint satisfiablity and aggregates all logical statements into one combined table. Made with React.",
    link:"https://fanrco.github.io/LogicTables/",
    image:LogicTableIcon,
  },
  {
    title:"Plantest",
    description: "A web based garden planner made for Citrus Hack 2020 in 24 hours using pure HTML/JS. It won 1st place in it's category. The app is no longer functional due to old asset links, but you can still view the Devpost submission!",
    link:"https://devpost.com/software/plantest-pvc28s",
    image:PlantestIcon,
  },

]

export const faceAnim = [
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face1,
  face0,
  face0,
  face0,
  face2,
  face0,
  face0,
  face0,
  face0,
  face0,
  face1,
  face2,
  face3,
  face4,
  face5,
  face6,
  face0,
  face3,
  face5,
  face3,
];
